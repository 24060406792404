import {
    required,
    minValue,
    maxLength,
  } from 'vuelidate/lib/validators';
  import { DateFormater } from "@/_helpers/funciones";
  
  export default (ValidPreviousDate, LaterDate, IssueDate, FinishDate) => {

    return {
        NoOrder: { required },
        OrderCode: { required },
        TransactionDate: { required, TimeValidate (value){
          return (DateFormater.formatDateTimeWithoutSlash(value) >= ValidPreviousDate && 
          DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
          DateFormater.formatDateTimeWithoutSlash(value) >= IssueDate && 
          DateFormater.formatDateTimeWithoutSlash(value) <= FinishDate)
        } },
        Transportation: {  },
        vehicle: {  },
        client: {  },
        scrapContainer: { required },
        Yard: {  },
        truckScale: { required },
        MovementType: { required },
        Observation: { maxLength: maxLength(250) },
    }
  }